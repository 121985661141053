import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaCoins } from 'react-icons/fa6';
import BoxJoinGroup from './BoxJoinGroup';
import { fetchShopeeSpinners } from '../../api/shopeeSpinnerCoinApi';

const ShopeeSpinner = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allSpinner = await fetchShopeeSpinners();
        setData(allSpinner);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (data.length === 0) {
    return <div>Không có dữ liệu. Hãy thử tải lại trang.</div>;
  }
  

  return (
    <div className="container mx-auto">
      <img src="/images/banners/shopee-spinner-coin.png" alt='Shopee Coin' className='rounded-md' />
      <BoxJoinGroup />
      {/* Box hiển thị khung hoàn tiền Shopee */}
      <div className="p-1 border rounded-md shadow-md flex flex-col bg-white mb-4">
          <div className="flex">
              <div className="w-1/4 flex flex-col items-center justify-center">
                  <img src="/images/logo-shopee-coupon.png" alt="Hoàn tiền Shopee" className="w-11 h-auto rounded-xl mb-1 bg-[#ee4d2d]" />
                  <p className="text-md">Shopee</p>
              </div>
              <div className="w-full px-2 flex flex-col justify-between">
                  <p className="text-md font-bold">Hoàn tiền đến 50% giá trị đơn hàng</p>
                  <div className="flex justify-between">
                      <div className="items-start">
                      <p className="text-sm text-gray-500">#Áp dụng với tất cả user</p>
                      <a href="/huong-dan" className="text-sm text-green-700 underline">Xem hướng dẫn</a>
                      </div>
                      <div className="text-sm flex items-center justify-end">
                          <Link to="/shopee">
                              <button className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher">Nhận ngay</button>
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* Box hiển thị khung hoàn tiền Lazada */}
      <div className="p-1 border rounded-md shadow-md flex flex-col bg-white mb-4">
          <div className="flex">
              <div className="w-1/4 flex flex-col items-center justify-center">
                  <img src="/images/logo-lazada-coupon.png" alt="Hoàn tiền Lazada" className="w-11 h-auto rounded-xl mb-1 bg-[#0a00c5]" />
                  <p className="text-md">Lazada</p>
              </div>
              <div className="w-full px-2 flex flex-col justify-between">
                  <p className="text-md font-bold">Hoàn tiền đến 40% giá trị đơn hàng</p>
                  <div className="flex justify-between">
                      <div className="items-start">
                      <p className="text-sm text-gray-500">#Áp dụng với tất cả user</p>
                      <a href="/huong-dan" className="text-sm text-green-700 underline">Xem hướng dẫn</a>
                      </div>
                      <div className="text-sm flex items-center justify-end">
                          <Link to="/lazada">
                              <button className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher">Nhận ngay</button>
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* Box hiển thị khung hoàn tiền TikTok */}
      <div className="p-1 border rounded-md shadow-md flex flex-col bg-white mb-4">
          <div className="flex">
              <div className="w-1/4 flex flex-col items-center justify-center">
                  <img src="/images/logo-tiktok-coupon.png" alt="Hoàn tiền Tiktok Shop" className="w-11 h-auto rounded-xl mb-1 bg-white" />
                  <p className="text-md">TikTok</p>
              </div>
              <div className="w-full px-2 flex flex-col justify-between">
                  <p className="text-md font-bold">Hoàn tiền đến 60% giá trị đơn hàng</p>
                  <div className="flex justify-between">
                      <div className="items-start">
                      <p className="text-sm text-gray-500">#Áp dụng với tất cả user</p>
                      <a href="/huong-dan" className="text-sm text-green-700 underline">Xem hướng dẫn</a>
                      </div>
                      <div className="text-sm flex items-center justify-end">
                          <Link to="/tiktokshop">
                              <button className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher">Nhận ngay</button>
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <p className='bg-green-700 text-md sm:text-base text-white px-4 py-2 rounded mb-4'>Bấm theo dõi shop trước khi quay. Giới hạn 10 lượt quay/ngày.</p>
      {data.map((item, index) => (
        <CountdownWrapper key={item._id} item={item} setData={setData} index={index + 1} />
      ))}
    </div>
  );
};

const CountdownWrapper = ({ item, setData, index }) => {
  const [isExpired, setIsExpired] = useState(false);
  const user = useSelector((state) => state.profile.user);
  const idUser = user?.idUser || 'notlogin';

  useEffect(() => {
    if (isExpired) {
      setData(prevData => prevData.filter(dataItem => dataItem._id !== item._id));
    }
  }, [isExpired, setData, item._id]);

  const formatName = (name) => {
    return name.length > 18 ? `${name.slice(0, 18)}...` : name;
  };

  const handleVisitShop = (shopId) => {
    const targetUrl = `https://s.shopee.vn/an_redir?origin_link=https://shopee.vn/shop/${shopId}`;

    if (idUser === "notlogin") {
      window.open(`/redirect?urlnotlogin=${encodeURIComponent(`${targetUrl}&affiliate_id=17345920097&sub_id=t3g-quayxu`)}`); 
    } else {
      window.open(`${targetUrl}&affiliate_id=17358790110&sub_id=tui3gang-${idUser}`); 
    }
  };

  return (
    !isExpired && (
      <div className="relative bg-white shadow-md rounded-lg p-3 mb-4 flex justify-between items-center">
        <div className="text-xs absolute top-0 left-0 bg-green-700 text-white px-1 rounded-br-md">
          {index}
        </div>
        <div>
          <h2 className="text-base sm:text-lg font-semibold">{formatName(item.shopName || item.userName)}</h2>
          <p className="text-xl font-bold text-amber-400 flex items-center">
            <span className='text-base text-black font-normal mr-1'>Nhận xu:</span> <span className='mr-1'>{item.maxcoin}</span> <FaCoins />
          </p>
          <p className='text-base'>Slot: {item.slot}</p>
        </div>
        <div className="flex flex-col items-end">
          <button
            onClick={() => handleVisitShop(item.shopId)}
            className="bg-green-700 text-white px-4 py-1 rounded hover:bg-green-600 mb-2"
          >
            Đến ngay
          </button>
          <div className="flex items-center">
            <span>⏰</span><Countdown startTime={item.startTime} setIsExpired={setIsExpired} />
          </div>
        </div>
      </div>
    )
  );
};

const Countdown = ({ startTime, setIsExpired }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = new Date(startTime) - new Date();
    let timeLeft = { total: difference };

    if (difference > 0) {
      const seconds = Math.floor(difference / 1000);
      timeLeft = {
        ...timeLeft,
        minutes: Math.floor(seconds / 60),
        seconds: seconds % 60,
      };
    }

    return timeLeft;
  }, [startTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (newTimeLeft.total <= 0) {
        setIsExpired(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft, setIsExpired]);

  return (
    <div className="flex">
      {timeLeft.total > 0 ? (
        timeLeft.minutes > 0 ? (
          <span className="font-bold">
            {timeLeft.minutes} phút {timeLeft.seconds} giây
          </span>
        ) : (
          <span className="font-bold">{timeLeft.seconds} giây</span>
        )
      ) : null}
    </div>
  );
};

export default ShopeeSpinner;